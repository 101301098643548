import { h } from 'vue'

import {
  createRouter,
  createWebHistory,
  RouterView,
  type RouteLocationNormalized
} from 'vue-router'

import middlewarePipeline from '@/router/middlewarePipeline'

import guest from '@/router/middlewares/guest'
import creator from '@/router/middlewares/creator'
import business from '@/router/middlewares/business'
import businessGuest from '@/router/middlewares/businessGuest'
import type { MiddlewareContext } from '@/router/types.ts'
import { getCurrentUser } from 'vuefire'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),

  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/pages/home/Layout.vue'),
      children: [
        {
          path: '',
          name: 'landing',
          component: () => import('@/pages/home/Landing.vue')
        },
        {
          path: '/pricing',
          name: 'pricing',
          component: () => import('@/pages/home/Pricing.vue')
        },
        {
          path: '/@:username',
          name: 'public.creatorProfile',
          meta: {
            middleware: [businessGuest]
          },
          component: () => import('@/pages/creator/profile.vue')
        },
        {
          path: '/i/:username',
          name: 'public.creatorProfileLegacy',
          component: () => import('@/pages/creator/profile.vue')
        },
      ]
    },

    {
      path: '/auth',
      component: { render: () => h(RouterView) },
      meta: {
        middleware: [guest]
      },
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('../pages/sign-in.vue')
        },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          // todo: remove when ios update propagates
          alias: '/password-reset',
          component: () => import('../pages/forgot-password.vue')
        },
        {
          path: '/reset-password',
          name: 'reset-password',
          component: () => import('../pages/reset-password.vue')
        },
      ]
    },
    {
      path: '/verify-email/:id',
      name: 'verify-email',
      component: () => import('../pages/verify-email.vue')
    },
    {
      path: '/invitation',
      name: 'invitation',
      component: () => import('../pages/invitation.vue')
    },
    {
      path: '/business/signup',
      name: 'signup',
      component: () => import('@/pages/auth/sign-up.vue'),
      meta: {
        middleware: [guest]
      }
    },
    {
      path: '/business',
      component: () => import('@/pages/business/index.vue'),
      name: 'business',
      meta: {
        middleware: [business]
      },

      children: [
        {
          path: ':brandId/collaborations',
          name: 'business.collaborations',
          component: () => import('@/pages/business/collaborations/index.vue'),
          props: (route: RouteLocationNormalized) => ({
            brandId: parseInt(route.params.brandId as string)
          }),

          redirect: () => ({ name: 'business.collaborations.new' }),

          children: [
            {
              path: 'new',
              name: 'business.collaborations.new',
              props: (route: RouteLocationNormalized) => ({
                brandId: parseInt(route.params.brandId as string)
              }),
              component: () => import('@/pages/business/collaborations/new.vue')
            },
            {
              path: 'upcoming',
              name: 'business.collaborations.accepted',
              props: (route: RouteLocationNormalized) => ({
                brandId: parseInt(route.params.brandId as string)
              }),
              component: () => import('@/pages/business/collaborations/upcoming.vue')
            },
            {
              path: 'waiting-for-content',
              name: 'business.collaborations.waitingForContent',
              props: (route: RouteLocationNormalized) => ({
                brandId: parseInt(route.params.brandId as string)
              }),
              component: () => import('@/pages/business/collaborations/waiting-for-content.vue')
            },
            {
              path: 'past',
              name: 'business.collaborations.completed',
              props: true,
              component: () => import('@/pages/business/collaborations/past.vue')
            },
          ]
        },
        {
          path: ':brandId/collaborations/:id',
          props: true,
          name: 'business.collaboration-details',
          component: () => import('@/pages/business/collaborations/_id/index.vue')
        },
        {
          path: ':brandId/campaigns',
          component: { render: () => h(RouterView) },
          name: 'business.campaigns',
          redirect: () => ({ name: 'business.campaigns.index' }),

          children: [
            {
              path: '',
              name: 'business.campaigns.index',
              component: () => import('@/pages/business/campaigns/index.vue'),
              props: (route: RouteLocationNormalized) => ({
                brandId: parseInt(route.params.brandId as string)
              })
            },
            {
              path: 'new',
              name: 'business.campaigns.new',
              component: () => import('@/pages/business/campaigns/new.vue'),
              props: (route: RouteLocationNormalized) => ({
                brandId: parseInt(route.params.brandId as string)
              })
            },
            {
              path: 'new-paid',
              name: 'business.campaigns.new-paid',
              component: () => import('@/pages/business/campaigns/new.vue'),
              props: (route: RouteLocationNormalized) => ({
                brandId: parseInt(route.params.brandId as string),
                paid: true,
              })
            },
            {
              path: ':id/update',
              name: 'business.campaigns.update',
              component: () => import('@/pages/business/campaigns/_id/update.vue'),
              props: (route: RouteLocationNormalized) => ({
                id: parseInt(route.params.id as string),
                brandId: parseInt(route.params.brandId as string)
              })
            },
            {
              path: 'archived',
              name: 'business.campaigns.archived',
              component: () => import('@/pages/business/campaigns/archived.vue'),
              props: (route: RouteLocationNormalized) => ({
                brandId: parseInt(route.params.brandId as string)
              })
            }
          ]
        },

        {
          path: ':brandId/messages',
          component: { render: () => h(RouterView) },
          name: 'business.messages',
          children: [
            {
              path: '',
              name: 'business.messages.index',
              component: () => import('@/pages/business/messages/index.vue'),
              props: (route: RouteLocationNormalized) => ({
                brandId: parseInt(route.params.brandId as string)
              })
            },
          ]

        },
        {
          path: ':brandId/locations',
          component: { render: () => h(RouterView) },
          name: 'business.locations',
          children: [
            {
              path: '',
              name: 'business.locations.index',
              component: () => import('@/pages/business/locations/index.vue'),
              props: (route: RouteLocationNormalized) => ({
                brandId: parseInt(route.params.brandId as string)
              })
            },
            {
              path: 'new',
              name: 'business.locations.new',
              component: () => import('@/pages/business/locations/new.vue'),
              props: (route: RouteLocationNormalized) => ({
                brandId: parseInt(route.params.brandId as string)
              })
            },
            {
              path: ':id/update',
              name: 'business.locations.update',
              component: () => import('@/pages/business/locations/_id/update.vue'),
              props: (route: RouteLocationNormalized) => ({
                id: parseInt(route.params.id as string),
                brandId: parseInt(route.params.brandId as string)
              })
            }
          ]
        },
        {
          path: ':brandId/posts',
          name: 'business.posts',
          component: () => import('@/pages/business/posts/index.vue'),
          props: (route: RouteLocationNormalized) => ({
            brandId: parseInt(route.params.brandId as string)
          })
        },
        {
          path: ':brandId/posts/:id',
          props: true,
          name: 'business.posts.details',
          component: () => import('@/pages/business/posts/_id/index.vue')
        },
        {
          path: ':brandId/socials',
          name: 'business.socials',
          component: () => import('@/pages/business/socials/index.vue'),
          props: (route: RouteLocationNormalized) => ({
            brandId: parseInt(route.params.brandId as string)
          })
        },
        {
          path: ':brandId/listings',
          name: 'business.listings',
          component: () => import('@/pages/business/listings/index.vue'),
          props: (route: RouteLocationNormalized) => ({
            brandId: parseInt(route.params.brandId as string)
          })
        },
        {
          path: ':brandId/team',
          name: 'business.team',
          component: () => import('@/pages/business/teams/index.vue'),
          props: (route: RouteLocationNormalized) => ({
            brandId: parseInt(route.params.brandId as string)
          })
        },
        {
          path: ':brandId/account',
          name: 'business.account',
          component: () => import('@/pages/business/account/index.vue'),
          props: (route: RouteLocationNormalized) => ({
            brandId: parseInt(route.params.brandId as string)
          })
        },
        {
          path: ':brandId/feature-request',
          name: 'business.feature-request',
          component: () => import('@/pages/business/feature-request/index.vue'),
          props: (route: RouteLocationNormalized) => ({
            brandId: parseInt(route.params.brandId as string)
          })
        },
        {
          path: ':brandId/billing',
          name: 'business.billing',
          component: () => import('@/pages/business/billing/index.vue'),
          props: (route: RouteLocationNormalized) => ({
            brandId: parseInt(route.params.brandId as string)
          })
        },
        {
          path: 'brands',
          component: { render: () => h(RouterView) },
          name: 'business.brands',
          children: [
            {
              path: 'new',
              name: 'business.brands.new',
              component: () => import('@/pages/business/brands/new.vue')
            }
          ]
        }
      ]
    },

    {
      path: '/collaboration/:id(.*)',
      name: 'collaboration-details',
      component: () => import('@/pages/business/collaborations/_id/legacy-index.vue'),
      meta: {
        middleware: [business]
      }
    },

    {
      path: '/creator:pathMatch(.*)*',
      name: 'creator',
      meta: {
        middleware: [creator]
      },
      component: () => import('@/pages/creator/announcement.vue')
    },

    {
      path: '/stripe-status/:accountId',
      name: 'stripe-account-connected',
      component: () => import('@/pages/stripe-account-connected.vue')
    },

    {
      path: '/legal/privacy-policy',
      name: 'privacy-policy',
      component: {
        template: `<iframe
                class="w-screen h-screen"
                src="https://app.termly.io/document/privacy-policy/4f2f7b7d-8a77-4caf-a685-907c88d5b6e3">
            </iframe>`
      },
      alias: '/privacy.html'
    },
    {
      path: '/legal/terms-of-conditions',
      name: 'terms-of-conditions',
      component: {
        template: `<iframe
                class="w-screen h-screen"
                src="https://app.termly.io/document/terms-of-service/e4459c0f-25a8-40b6-9e82-d483db1eaaf5">
            </iframe>`
      },
      alias: '/tos.pdf'
    },
    {
      path: '/legal/cookie-policy',
      name: 'cookie-policy',
      component: {
        template: `<iframe
                class="w-screen h-screen"
                src="https://app.termly.io/document/cookie-policy/0346f596-f9ff-4916-9090-297bdca4f7df">
            </iframe>`
      }
    },

    {
      path: '/legal/eula',
      name: 'eula',
      component: {
        template: `<iframe
                class="w-screen h-screen"
                src="https://app.termly.io/document/cookie-policy/0346f596-f9ff-4916-9090-297bdca4f7df">
            </iframe>`
      }
    },

    {
      path: '/legal/refund-policy',
      name: 'refund-policy',
      component: {
        template: `<iframe
                class="w-screen h-screen"
                src="https://app.termly.io/document/refund-policy/cf36d90b-c188-4d56-a0df-4ecda2a6370a">
            </iframe>`
      }
    },
    {
      path: '/legal/disclaimer',
      name: 'disclaimer',
      component: {
        template: `<iframe
                class="w-screen h-screen"
                src="https://app.termly.io/document/disclaimer/63b16df0-288b-41ca-ab15-3789237233f0">
            </iframe>`
      }
    },
    {
      path: '/legal/acceptable-use',
      name: 'acceptable-use',
      component: {
        template: `<iframe
                class="w-screen h-screen"
                src="https://app.termly.io/document/acceptable-use/88d3ef4c-e229-4c80-ab2a-2d0244ac9035">
            </iframe>`
      }
    },

    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import('../pages/unauthorized.vue')
    },

    {
      path: '/:pathMatch(.*)*',
      name: 'notfound',
      component: () => import('../pages/not-found.vue')
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const middleware = to.meta.middleware

  if (!middleware) {
    return next()
  }

  const context = { to, from, next }

  // @ts-ignore
  await middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    window.location.href = to.fullPath
  }
})

export default router
