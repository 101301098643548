/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UIBadges } from '../models/UIBadges';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StatsService {
    /**
     * Ui Badges
     * @param brandId
     * @returns UIBadges Successful Response
     * @throws ApiError
     */
    public static uiBadges(
        brandId: number,
    ): CancelablePromise<UIBadges> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stats/ui-badges/{brand_id}',
            path: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
