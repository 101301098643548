/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_collaboration_upload_raw } from '../models/Body_collaboration_upload_raw';
import type { ChangeCopyrightPriceRequest } from '../models/ChangeCopyrightPriceRequest';
import type { CollaborationCancel } from '../models/CollaborationCancel';
import type { CollaborationCreate } from '../models/CollaborationCreate';
import type { CollaborationDetails } from '../models/CollaborationDetails';
import type { CollaborationRead } from '../models/CollaborationRead';
import type { CollaborationReschedule } from '../models/CollaborationReschedule';
import type { CollaborationStatus } from '../models/CollaborationStatus';
import type { FileRead } from '../models/FileRead';
import type { RescheduleRequest } from '../models/RescheduleRequest';
import type { StatusCount } from '../models/StatusCount';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CollaborationService {
    /**
     * Create collaboration
     * Create collaboration from creator and offer,        by default uses first creator profile from current signed in user.
     * @param requestBody
     * @returns CollaborationRead Successful Response
     * @throws ApiError
     */
    public static creatorBook(
        requestBody: CollaborationCreate,
    ): CancelablePromise<CollaborationRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collaborations/book',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Cancel collaboration
     * Cancel existing collaboration.
     * @param requestBody
     * @returns CollaborationRead Successful Response
     * @throws ApiError
     */
    public static creatorCancel(
        requestBody: CollaborationCancel,
    ): CancelablePromise<CollaborationRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collaborations/cancel',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reschedule collaboration
     * Reschedule existing collaboration.
     * @param requestBody
     * @returns CollaborationRead Successful Response
     * @throws ApiError
     */
    public static creatorReschedule(
        requestBody: CollaborationReschedule,
    ): CancelablePromise<CollaborationRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collaborations/reschedule',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List collaborations
     * List all collaborations for creator including past, cancelled or expired.
     * @param limit
     * @returns CollaborationDetails Successful Response
     * @throws ApiError
     */
    public static creatorListAll(
        limit: number = 50,
    ): CancelablePromise<Array<CollaborationDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collaborations/list',
            query: {
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Approve a collaboration
     * Approve collaboraiton request from creator
     * @param collaborationId
     * @param additionalDetails
     * @returns CollaborationDetails Successful Response
     * @throws ApiError
     */
    public static approve(
        collaborationId: number,
        additionalDetails: string,
    ): CancelablePromise<CollaborationDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collaborations/{collaboration_id}/approve',
            path: {
                'collaboration_id': collaborationId,
            },
            query: {
                'additional_details': additionalDetails,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Complete
     * @param collaborationId
     * @returns CollaborationDetails Successful Response
     * @throws ApiError
     */
    public static complete(
        collaborationId: number,
    ): CancelablePromise<CollaborationDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collaborations/{collaboration_id}/complete',
            path: {
                'collaboration_id': collaborationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Archive
     * @param collaborationId
     * @param dismissReason
     * @returns CollaborationDetails Successful Response
     * @throws ApiError
     */
    public static archive(
        collaborationId: number,
        dismissReason: string,
    ): CancelablePromise<CollaborationDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collaborations/{collaboration_id}/archive',
            path: {
                'collaboration_id': collaborationId,
            },
            query: {
                'dismiss_reason': dismissReason,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reject a collaboration
     * Reject collaboraiton request from creator
     * @param collaborationId
     * @param rejectReason
     * @returns CollaborationDetails Successful Response
     * @throws ApiError
     */
    public static reject(
        collaborationId: number,
        rejectReason: string,
    ): CancelablePromise<CollaborationDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collaborations/{collaboration_id}/reject',
            path: {
                'collaboration_id': collaborationId,
            },
            query: {
                'reject_reason': rejectReason,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Request reschedule for a collaboration
     * Request reschedule for a collaboration due to availability issues
     * @param collaborationId
     * @param requestBody
     * @returns CollaborationDetails Successful Response
     * @throws ApiError
     */
    public static requestReschedule(
        collaborationId: number,
        requestBody: RescheduleRequest,
    ): CancelablePromise<CollaborationDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collaborations/{collaboration_id}/request_reschedule',
            path: {
                'collaboration_id': collaborationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get By Brand
     * @param brandId
     * @param status
     * @param limit
     * @returns CollaborationDetails Successful Response
     * @throws ApiError
     */
    public static getByBrand(
        brandId: number,
        status?: (Array<CollaborationStatus> | null),
        limit: number = 100,
    ): CancelablePromise<Array<CollaborationDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collaborations/get_by_brand/{brand_id}',
            path: {
                'brand_id': brandId,
            },
            query: {
                'status': status,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Status Count By Brand
     * @param brandId
     * @returns StatusCount Successful Response
     * @throws ApiError
     */
    public static getStatusCountByBrand(
        brandId: number,
    ): CancelablePromise<StatusCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collaborations/get_by_brand/{brand_id}/status-count',
            path: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upload Raw
     * @param collaborationId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static uploadRaw(
        collaborationId: number,
        formData: Body_collaboration_upload_raw,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collaborations/{collaboration_id}/add-files',
            path: {
                'collaboration_id': collaborationId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Files
     * @param collaborationId
     * @returns FileRead Successful Response
     * @throws ApiError
     */
    public static getFiles(
        collaborationId: number,
    ): CancelablePromise<Array<FileRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collaborations/{collaboration_id}/files',
            path: {
                'collaboration_id': collaborationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete File
     * @param fileId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteFile(
        fileId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collaborations/{collaboration_id}/files/{file_id}/delete',
            path: {
                'file_id': fileId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get collaboration
     * Get collaboration details.
     * @param collaborationId
     * @returns CollaborationDetails Successful Response
     * @throws ApiError
     */
    public static get(
        collaborationId: number,
    ): CancelablePromise<CollaborationDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collaborations/{collaboration_id}',
            path: {
                'collaboration_id': collaborationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update License Price
     * @param collaborationId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateLicensePrice(
        collaborationId: number,
        requestBody: ChangeCopyrightPriceRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/collaborations/{collaboration_id}/copyright-price',
            path: {
                'collaboration_id': collaborationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
